import {useCallback, useEffect, useMemo, useState} from 'react';
import type {JobBoardFilterConfig} from '~/shared/types/job-board-filter';

import type {SetRequired} from 'type-fest';
import {useJobBoardFilter} from './use-job-board-filter';

export const useFilterState = () => {
	const {filter} = useJobBoardFilter();

	const currentFilter = useMemo(
		() => ({
			description: filter.description || [],
			typeId: filter.typeId || '',
			hasRemote: filter.hasRemote,
			experienceLevel: filter.experienceLevel || '',
			maxAge: filter.maxAge,
			countryCode: filter.countryCode || '',
			cityName: filter.cityName || '',
			stateId: filter.stateId,
			stateName: filter.stateName || '',
			companyMaxSize: filter.companyMaxSize,
			companyIndustryId: filter.companyIndustryId,
			companyName: filter.companyName || '',
			companyId: filter.companyId,
			location: filter.location,
			regionId: filter.regionId,
			minSalary: filter.minSalary,
		}),
		[filter],
	);

	const [filterState, setFilterState] =
		useState<
			SetRequired<
				JobBoardFilterConfig,
				'description' | 'typeId' | 'experienceLevel' | 'countryCode' | 'cityName' | 'companyName'
			>
		>(currentFilter);

	useEffect(() => {
		setFilterState(currentFilter);
	}, [currentFilter]);

	const resetFilterState = useCallback(() => {
		setFilterState(currentFilter);
	}, [currentFilter]);

	return {
		filterState,
		setFilterState,
		resetFilterState,
	};
};
