'use client';

import {Logo} from '@job-ish/blocks/components';
import {Anchor} from '@job-ish/ui/components';
import {IconLogin2} from '@tabler/icons-react';
import clsx from 'clsx';
import NextLink from 'next/link';
import {useParams} from 'next/navigation';

export const Header = () => {
	const {id} = useParams();

	return (
		<header className={clsx('relative h-14 w-dvw px-2.5', id && 'hidden sm:block')}>
			<nav className="relative flex w-full justify-between px-2">
				<a className="z-50 w-[180px]" href="https://job-ish.com" tabIndex={-1} target="_blank">
					<div className="absolute flex w-fit items-center justify-center gap-2 sm:justify-start">
						<Logo />
						<h1 className="sr-only">job-ish</h1>
					</div>
				</a>
				<NextLink
					className="focus-visible:outline-none"
					href="/auth/login"
					passHref
					scroll={false}
					tabIndex={-1}
					title="Log In"
				>
					<Anchor
						as="div"
						className="my-4 flex text-sm font-semibold text-mauve11 visited:text-mauve11"
						suffix={<IconLogin2 className="h-4 w-4" />}
					>
						Log In
					</Anchor>
				</NextLink>
			</nav>
		</header>
	);
};
